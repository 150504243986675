import React from "react";
import arrayDestruct from "../assets/portfolio/pemenangan.png";
import installNode from "../assets/portfolio/payroll.png";
import navbar from "../assets/portfolio/perekrutan.png";
import reactParallax from "../assets/portfolio/info.png";
import reactSmooth from "../assets/portfolio/inventory.png";
import bantaeng from "../assets/portfolio/bantaeng.png";
import survey from "../assets/portfolio/masyarakat.png";

const Portfolio = () => {
	const portfolios = [
		{
			id: 4,
			src: reactSmooth,
			name: "IT Inventory Kawasan Berikat",
		},
		{
			id: 1,
			src: arrayDestruct,
			name: "Survey Pemenangan Calon Bupati",
		},

		{
			id: 3,
			src: navbar,
			name: "System Perekrutan Online Pada Kawasan Industri Bantaeng",
		},

		{
			id: 5,
			src: installNode,
			name: "Payroll Karyawan Tenant Pada Kawasan Industri Bantaeng",
		},
		{
			id: 6,
			src: bantaeng,
			name: "Web Pemenangan Caleg Jaringan Suara Indonesia ",
		},
		{
			id: 8,
			src: arrayDestruct,
			name: "DPRD Campaign With Micro Targeting Apps",
		},
	];

	return (
		<div
			name="portfolio"
			className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-screen">
			<div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
				<div className="pb-8">
					<p className="text-4xl font-bold inline border-b-4 border-gray-500">
						Portfolio
					</p>
					<p className="py-6">Check Project Yang Telah Saya Selesaikan</p>
				</div>
				<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-12 sm:px-0">
					{portfolios.map(({ id, src, name }) => (
						<div key={id} className="shadow-md shadow-gray-600 rounded-lg">
							<img
								src={src}
								alt="Portfolio"
								className="rounded-md duration-200 hover:scale-125 "
							/>
							<div className="flex items-center justify-center">
								<button className="w-full px-6 py-3 m-4 duration-200 hover:scale-105">
									{name}
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Portfolio;
