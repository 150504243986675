import React from "react";

import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import nextjs from "../assets/nextjs.png";
import graphql from "../assets/nest.png";
import github from "../assets/github.png";
import tailwind from "../assets/tailwind.png";
import docker from "../assets/docker.png";

const Experience = () => {
	const techs = [
		{
			id: 1,
			src: html,
			title: "HTML",
			style: "shadow-orange-500",
		},
		{
			id: 2,
			src: css,
			title: "CSS",
			style: "shadow-blue-500",
		},
		{
			id: 3,
			src: javascript,
			title: "JavaScript",
			style: "shadow-yellow-500",
		},
		{
			id: 4,
			src: reactImage,
			title: "React",
			style: "shadow-blue-600",
		},
		{
			id: 5,
			src: tailwind,
			title: "Tailwind",
			style: "shadow-sky-400",
		},
		{
			id: 6,
			src: nextjs,
			title: "Next JS",
			style: "shadow-white",
		},
		{
			id: 7,
			src: graphql,
			title: "NestJs",
			style: "shadow-red-400",
		},
		{
			id: 8,
			src: github,
			title: "GitHub",
			style: "shadow-gray-400",
		},
		{
			id: 9,
			src: docker,
			title: "Docker",
			style: "shadow-gray-400",
		},
	];

	return (
		<div
			name="experience"
			className="w-full md:h-screen h-[100%] bg-gradient-to-b from-gray-800 to-black text-white">
			<div className="max-w-screen-lg md:p-4 p-5 mx-auto flex flex-col justify-center w-full h-full">
				<div className="md:pb-8 pb-0">
					<p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
						Experience
					</p>
					<p className="py-6">These are the technologies I've worked with</p>
				</div>

				<div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
					{techs.map(({ id, src, title, style }) => (
						<div
							key={id}
							className={`shadow-md bg-gray-700 hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>
							<img src={src} alt="" className="w-20 mx-auto" />
							<p className="">{title}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Experience;
