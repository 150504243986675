import React from "react";
import HeroImage from "../assets/profil.jpeg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-scroll";

const Home = () => {
	return (
		<div
			name="home"
			className="md:h-screen h-[100%] w-full bg-gradient-to-b from-black via-black to-gray-800">
			<div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
				<div className="flex flex-col justify-center h-full">
					<h2 className="text-4xl mt-24 sm:text-7xl font-bold text-white">
						I'm a NodeJS Developer
					</h2>
					<p className="text-gray-500 py-4 max-w-md">
						Halo, saya Asdar Said, lulusan informatika yang ingin menggadai
						semua ijazahnya karna belum berguna sampai sekarang hehe. <br />{" "}
						Saya seorang software developer berusia 24 tahun yang menjadi
						freelance nodejs developer, dan founder di CV AS Media Kreatif.
					</p>
					<div>
						<Link
							to="portfolio"
							smooth
							duration={500}
							className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer">
							Portfolio
							<span className="group-hover:rotate-90 duration-300">
								<MdOutlineKeyboardArrowRight size={25} className="ml-1" />
							</span>
						</Link>
					</div>
				</div>
				<div>
					<img
						src={HeroImage}
						alt="my profile"
						className="rounded-2xl mt-5 mx-auto w-2/3 "
					/>
				</div>
			</div>
		</div>
	);
};

export default Home;
