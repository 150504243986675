import React from "react";

const About = () => {
	return (
		<>
			<div
				name="about"
				className="w-full md:h-screen h-[100%] bg-gradient-to-b from-gray-800 to-black text-white">
				<div className="max-w-screen-lg md:p-4 p-5 mx-auto flex flex-col justify-center w-full h-full">
					<div className="md:pb-8 pb-0">
						<p className="text-4xl font-bold inline border-b-4 border-gray-500">
							About
						</p>
					</div>
					<p className="text-xl md:mt-10 mt-5">
						Saya seorang software developer dengan minat dan dedikasi yang
						tinggi dalam menciptakan solusi-solusi teknologi yang inovatif.{" "}
						<br /> Saya sangat terinspirasi oleh kemampuan software dalam
						mengubah dunia, dan saya berusaha untuk menjadi bagian dari
						perubahan itu. Saya memiliki pengalaman yang solid dalam
						pengembangan perangkat lunak dengan menggunakan berbagai bahasa
						pemrograman seperti Javascript,php dan python.
					</p>
					<br />
					<div className="mt-24">
						<div className="md:pb-8 pb-0">
							<p className="text-4xl font-bold inline border-b-4 border-gray-500">
								Work Experience
							</p>
						</div>
					</div>
					<ol className="relative mt-8 text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
						<li className="ml-6">
							<span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
								<svg
									className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 18 20">
									<path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
								</svg>
							</span>
							<h3 className="text-xl text-white ml-16 leading-tight">
								PT.Cipta Media
							</h3>
							<p className="text-sm ml-16">2021-2022</p>
						</li>
						<li className="ml-6 mt-8">
							<span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
								<svg
									className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 18 20">
									<path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
								</svg>
							</span>
							<h3 className="text-xl text-white ml-16 leading-tight">
								PT.Indosistem Teknologi Utama
							</h3>
							<p className="text-sm ml-16">2022-2023</p>
						</li>
						<li className="ml-6 mt-8">
							<span className="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
								<svg
									className="w-3.5 h-3.5 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="currentColor"
									viewBox="0 0 18 20">
									<path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
								</svg>
							</span>
							<h3 className="text-xl text-white ml-16 leading-tight">
								PT.Huadi Bantaeng Industry Park
							</h3>
							<p className="text-sm ml-16">2023-Present</p>
						</li>
					</ol>
				</div>
			</div>
		</>
	);
};

export default About;
