import React from "react";

const About = () => {
	return (
		<div
			name="about"
			className="w-full md:h-screen h-[100%] bg-gradient-to-b from-gray-800 to-black text-white">
			<div className="max-w-screen-lg md:p-4 p-5 mx-auto flex flex-col justify-center w-full h-full">
				<div className="md:pb-8 pb-0">
					<p className="text-4xl font-bold inline border-b-4 border-gray-500">
						About
					</p>
				</div>
				<p className="text-xl md:mt-10 mt-5">
					Saya seorang software developer dengan minat dan dedikasi yang tinggi
					dalam menciptakan solusi-solusi teknologi yang inovatif. <br /> Saya
					sangat terinspirasi oleh kemampuan software untuk mengubah dunia, dan
					saya berusaha untuk menjadi bagian dari perubahan itu. Saya memiliki
					pengalaman yang solid dalam pengembangan perangkat lunak dengan
					menggunakan berbagai bahasa pemrograman seperti Nodejs,php dan python.
					Saya juga cukup mahir dalam merancang dan mengimplementasikan
					arsitektur software yang efisien dan skalabel. Selama karirku, saya
					telah terlibat dalam berbagai proyek pengembangan perangkat lunak.
					Saya senang bekerja dalam tim kolaboratif, berbagi pengetahuan dan
					ide-ide dengan rekan-rekan kerja. Saya percaya bahwa kolaborasi yang
					baik dan komunikasi yang efektif adalah kunci untuk mencapai hasil
					terbaik dalam pengembangan perangkat lunak.
				</p>
				<br />
			</div>
		</div>
	);
};

export default About;
